import { Box, VStack } from "@chakra-ui/layout";

// TODO: Maybe change this to Thanks instead of Credit

export default function Credit() {
    return (
        <VStack>
            <Box>Credit to:</Box>
            <Box>Joel Lawson for the project</Box>
            <Box>Alexander Liu for the design</Box>
        </VStack>
    )
}